<div class="row">
  <div class="col-12">
    <swiper [config]="swiperConfig" #swiperGallery class="swiper-gallery">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide px-4"
          *ngFor="let subscriptions of subscriptionsMatrix"
        >
          <div
            *ngFor="let subscription of subscriptions; let i = index"
            class="d-flex flex-column bg-white rounded-lg border-grey shadow-sm overflow-hidden"
            [ngClass]="{ 'mr-2': !!subscriptions[i + 1] }"
          >
            <div class="subscription-banner-container bg-grey bg-darken-2 mb-1">
              <img
                [src]="subscription.imgDesktop"
                alt="Banner da assinatura"
                class="w-100 subscription-banner"
              />
              <div class="destaque" *ngIf="subscription.isFeatured == true">
                <p>Destaque</p>
              </div>
            </div>

            <div class="p-3">
              <span class="d-block font-size-9 text-bold-600 mb-2">
                {{ subscription.title }}
              </span>
              <!-- <div
                *ngIf="!!subscription.value_with_discount"
                class="d-flex align-items-center mb-1"
              >
                <span
                  class="font-size-6 text-bold-600 grey lighten-1 value-discounted"
                >
                  {{ integerToReal(subscription.value) }}
                </span>
                <span
                  class="font-size-6 text-bold-600 badge badge-pill bg-light-success ml-1"
                >
                  {{ subscription.discount_percent }}
                </span>
              </div> -->
              <div class="row ml-2">
                <span
                  class="d-block font-size-8 text-bold-600 mb-2"
                  [style.color]="selectedColor"
                >
                  {{
                    subscription.value_with_discount
                      ? integerToReal(subscription.value_with_discount)
                      : integerToReal(subscription.value)
                  }}/
                </span>
                <div
                  *ngFor="let plan of subscription.Plans"
                  class="row align-items-center"
                >
                  <div class="col-auto">
                    <p class="subscription-interval">
                      {{ getIntervalTypeInPortuguese(plan.interval) }}
                    </p>
                  </div>
                  <div class="col-auto">
                    <p class="subscription-cycles p-1">
                      {{ plan.cycles }} meses
                    </p>
                  </div>
                </div>
              </div>
              <p class="mb-2 font-size-6 grey text-bold-500">
                {{
                  expandedDescriptions[i]
                    ? subscription.description
                    : getShortDescription(subscription.description)
                }}
              </p>
              <button class="btn btn-link p-0" (click)="toggleDescription(i)">
                {{ expandedDescriptions[i] ? "Mostrar menos" : "Mostrar mais" }}
              </button>
              <button
                type="button"
                (click)="openCheckout(subscription.url)"
                class="w-100 btn rounded-sm"
                [style.backgroundColor]="selectedColor"
                [style.color]="calculateTextColor(selectedColor)"
              >
                Comprar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="subscriptionsMatrix.length > 1"
        role="button"
        title="Anterior"
        [ngClass]="
          isMobile
            ? 'swiper-button-prev bg-white border-grey btn btn-outline-warning p-0 shadow-none round'
            : 'swiper-button-prev bg-white border-grey btn-sm btn-outline-warning p-0 shadow-none round'
        "
        (click)="goPrev()"
      ></div>

      <div
        *ngIf="subscriptionsMatrix.length > 1"
        role="button"
        title="Próximo"
        [ngClass]="
          isMobile
            ? 'swiper-button-next bg-white border-grey btn btn-outline-warning p-0 shadow-none round'
            : 'swiper-button-next bg-white border-grey btn-sm btn-outline-warning p-0 shadow-none round'
        "
        (click)="goNext()"
      ></div>
    </swiper>
    <div class="w-100 d-flex mt-3">
      <div class="mx-auto d-flex">
        <button
          *ngFor="let p of subscriptionsMatrix; let i = index"
          type="button"
          (click)="goToIndex(i)"
          class="btn btn-sm p-1 m-0 round"
          [ngClass]="{
            'bg-primary': swiperGallery?.swiper?.activeIndex === i,
            'bg-grey bg-lighten-1': swiperGallery?.swiper?.activeIndex !== i,
            'mr-2': !!subscriptionsMatrix[i + 1]
          }"
        ></button>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</div>
