import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Api from "app/helpers/api";
import App from "app/helpers/app";
import { realToFloat } from "app/helpers/realToFloat";
import * as CryptoJS from "crypto-js";
import * as moment from "moment";
import { ClipboardService } from "ngx-clipboard";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import Me from "../../../helpers/me";
import { Graphics } from "./graphics.types";
import { Observable } from "rxjs";
import { HttpParams } from "@angular/common/http";
@Component({
  selector: "app-finance-reports",
  templateUrl: "./finance-reports.component.html",
  styleUrls: ["./finance-reports.component.scss"],
})
export class FinanceReportsComponent implements OnInit {
  apiCall = this.api.new().silent();
  account = null;
  invoices = [];
  invoicesFiltered = [];
  withdraws = [];
  withdrawsFiltered = [];
  balancetoWithDraw = null;
  avaiableBalance = null;
  receivableBalance = null;
  balanceInCurrentMonth = null;
  inputWithdraw = null;
  page = 1;
  pageSize = 10;
  filterStatusFatura = null;
  filterCustomerName = null;
  autoWithdraw = null;
  chargebacks = null;
  chargebacksLocal = null;
  videoUrl = null;
  statusFatura = [
    {
      id: null,
      name: "Todos",
    },
    {
      id: "paid",
      name: "Pagas",
    },
    {
      id: "pending",
      name: "Pendentes",
    },
    {
      id: "canceled",
      name: "Canceladas",
    },
    {
      id: "expired",
      name: "Expiradas",
    },
    {
      id: "refunded",
      name: "Reembolsadas",
    },
  ];
  selectedPeriod: string = "year";
  chartData: any;
  financialData: any;
  productSalesData: any;
  constructor(
    private modal: NgbModal,
    private router: Router,
    private spinner: NgxSpinnerService,
    private api: Api,
    private app: App,
    private me: Me,
    private cdr: ChangeDetectorRef,
    private clipboardApi: ClipboardService,
    private _sanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    let localAccount = null;

    await this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.youtube.com/embed/LBRZqOHpcZA"
    );
    const subaccount = await this.apiCall
      .get("seller/me/marketplaceaccountlocal")
      .pipe(take(1))
      .toPromise();

    if (!subaccount.return) {
      this.router.navigate(["/page/personal/finance/account"]);
    } else {
      localAccount = subaccount.return;
    }

    if (
      (localAccount &&
        localAccount.identity_validation &&
        localAccount.identity_validation != 3) ||
      (localAccount && !localAccount.identity_validation)
    ) {
      this.router.navigate(["/page/personal/finance/account"]);
    }

    const accountInfos = await this.apiCall
      .get("seller/me/marketplaceaccount")
      .pipe(take(1))
      .toPromise();
    const chargebacksInfos = await this.apiCall
      .get("seller/me/chargebacksinfos")
      .pipe(take(1))
      .toPromise();

    if (!accountInfos.return) {
      this.router.navigate(["/page/personal/finance/account"]);
    }

    if (accountInfos.return) {
      this.account = accountInfos.return;
      this.balancetoWithDraw = this.account.balance_available_for_withdraw
        ? this.account.balance_available_for_withdraw
        : 0;
      this.avaiableBalance = this.account.balance_available_for_withdraw
        ? realToFloat(this.account.balance_available_for_withdraw) !== null
          ? realToFloat(this.account.balance_available_for_withdraw)
          : 0
        : 0;
      this.receivableBalance = this.account.receivable_balance
        ? this.account.receivable_balance
        : 0;
      this.balanceInCurrentMonth = this.account.volume_this_month
        ? this.account.volume_this_month
        : 0;
      this.autoWithdraw =
        this.account.auto_withdraw && this.account.auto_withdraw == true
          ? true
          : false;
    }

    const faturas = await this.apiCall
      .get("seller/me/subaccountinvoices")
      .pipe(take(1))
      .toPromise();

    if (faturas.return && faturas.return) {
      this.invoices = faturas.return;
      this.invoicesFiltered = this.invoices;
    }

    if (chargebacksInfos.return) {
      if (chargebacksInfos.return.chargebacks)
        this.chargebacks = chargebacksInfos.return.chargebacks;
      if (chargebacksInfos.return.chargebacksLocal)
        this.chargebacksLocal = chargebacksInfos.return.chargebacksLocal;

      if (this.chargebacks.items) {
        for (let c of this.chargebacks.items) {
          c.fatura = this.invoices.find((i) => i.invoiceId == c.invoice_id);
          c.expires_at = moment(c.expires_at)
            .subtract(2, "days")
            .format("YYYY-MM-DD");
          c.contest = this.chargebacksLocal.find((i) => i.chargebackId == c.id);
          c.feedback = this.getStatusEnvio(c);
        }
      }
    }

    const withdraws = await this.apiCall
      .get("seller/me/subaccountiwithdraws")
      .pipe(take(1))
      .toPromise();

    if (withdraws.return) {
      this.withdraws = withdraws.return;
      this.withdrawsFiltered = this.withdraws;
    }
    this.loadChartData();
    await this.spinner.hide();
  }

  async openSecureUrl(invoice) {
    const url = `https://app.opersonaldigital.com.br/pages/assinatura-aluno/${invoice.invoiceId}`;
    window.open(url, "_blank");
  }

  getStatusEnvio(chargeback) {
    const self = this;
    if (moment(moment(), "day").isSameOrBefore(chargeback.expires_at)) {
      if (chargeback.contest.Files && chargeback.contest.Files.length > 0) {
        return "enviado";
      } else {
        return "pendente";
      }
    } else if (
      chargeback.contest.Files &&
      chargeback.contest.Files.length > 0
    ) {
      return "enviado";
    } else {
      return "expirado";
    }
  }

  disableWithdraw() {
    const self = this;
    if (!this.inputWithdraw) {
      return false;
    } else if (this.inputWithdraw < 5.0) {
      return false;
    } else {
      return true;
    }
  }

  iuguWithdrawRequest(modal) {
    const self = this;
    let chamada = null;

    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    if (this.inputWithdraw) {
      let body = {
        amount: parseFloat(this.inputWithdraw).toFixed(2),
      };

      chamada = this.apiCall.post("seller/me/requestwithdraw", body);

      chamada.subscribe(async (data) => {
        if (data.success) {
          this.spinner.hide();
          await this.app.alert(
            "Tudo certo!",
            "Pedido de saque realizado com sucesso!",
            "success"
          );
          this.ngOnInit();
        } else {
          await this.app.alert("Ops :(", data.message, "error");
          this.spinner.hide();
        }
      });
    }
  }

  OpenModalSm(content) {
    this.modal.open(content, { centered: true, size: "sm" });
  }

  openModalSaque(content) {
    this.inputWithdraw = null;
    this.modal.open(content, { centered: true, size: "sm" });
  }

  OpenModalXl(content) {
    this.modal.open(content, { centered: true, size: "xl" });
  }

  OpenModalNormal(content) {
    this.modal.open(content, { centered: true });
  }

  formatDate(date) {
    moment.locale("pt-br");
    return moment(date).format("DD/MM/YYYY");
  }

  formatDateNotLocale(date) {
    return moment(date).add(1, "day").format("DD/MM/YYYY");
  }

  formatPreco(valor) {
    let retorno = (valor / 100).toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
    return retorno;
  }

  formatId(data) {
    let retorno = data.substr(0, 4) + " - " + data.substr(-4);
    return retorno;
  }

  copiarId(data) {
    this.clipboardApi.copyFromContent(data);
  }

  returnInvoiceStatus(status) {
    switch (status) {
      case "pending":
        return "Pendente";
      case "paid":
        return "Pago";
      case "expired":
        return "Expirada";
      case "canceled":
        return "Cancelada";
      case "refunded":
        return "Reembolsada";
      case "done":
        return "Liberado";
    }
  }

  returnWithdrawStatus(status) {
    switch (status) {
      case "pending":
        return "Pendente";
      case "processing":
        return "Processando";
      case "accepted":
        return "Aceito";
    }
  }

  filter() {
    const self = this;
    this.invoicesFiltered = this.invoices;

    if (this.filterStatusFatura) {
      this.invoicesFiltered = this.invoicesFiltered.filter(
        (x) => x.status == this.filterStatusFatura.id
      );
    }
    if (this.filterCustomerName) {
      this.invoicesFiltered = this.invoicesFiltered.filter(
        (x) =>
          x.payer_name &&
          x.payer_name
            .toLowerCase()
            .includes(this.filterCustomerName.toLowerCase())
      );
    }
  }

  async reembolsarFatura(invoice) {
    const self = this;

    if (
      invoice.status == "paid" &&
      (invoice.paymentMethod == "iugu_credit_card" ||
        invoice.paymentMethod == "iugu_pix")
    ) {
      self.app
        .confirm(
          "Tem certeza?",
          "Ao Realizar o reembolso o valor será descontado do seu saldo atual!"
        )
        .then((d) => {
          if (d) {
            self.spinner.show(undefined, {
              type: "ball-triangle-path",
              size: "medium",
              bdColor: "rgba(0, 0, 0, 0.8)",
              color: "#fff",
              fullScreen: true,
            });

            let body = {
              invoiceId: invoice.invoiceId,
            };

            let chamada = this.apiCall.post("seller/me/refundinvoice", body);

            chamada.subscribe(async (data) => {
              if (data.success) {
                this.spinner.hide();
                await this.app.alert(
                  "Tudo certo!",
                  "Solicitação de reembolso realizada com sucesso!",
                  "success"
                );
                this.ngOnInit();
              } else {
                this.spinner.hide();
                await this.app.alert("Ops :(", data.message, "error");
              }
            });
          }
        });
    } else {
      await this.app.alert(
        "Ops :(",
        "Só é possível reembolsar faturas que estejam pagas com PIX ou Cartão de Crédito!",
        "error"
      );
    }
  }

  updateAcceptAutoWithdraw() {
    const self = this;

    Swal.fire({
      title: "Tem certeza?",
      text: this.autoWithdraw
        ? "Deseja ativar o saque automatico ?"
        : "Deseja desativar o saque automatico ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        self.spinner.show(undefined, {
          type: "ball-triangle-path",
          size: "medium",
          bdColor: "rgba(0, 0, 0, 0.8)",
          color: "#fff",
          fullScreen: true,
        });

        self.apiCall
          .put("seller/me/updateacceptautowithdraw", {
            autoWithdraw: this.autoWithdraw,
          })
          .subscribe(async (data) => {
            if (data.success) {
              this.spinner.hide();
              await this.app.alert(
                "Tudo certo!",
                "Preferência de saque alterada com sucesso",
                "success"
              );
              this.ngOnInit();
            } else {
              await this.app.alert("Ops :(", data.message, "error");
            }
          });
      } else if (result.isDismissed) {
        this.autoWithdraw = !this.autoWithdraw;
      }
    });
  }

  acatarContestacao(chargeback) {
    const self = this;

    Swal.fire({
      title: "Tem certeza que deseja acatar a contestação?",
      text: "Deseja acatar a contestação?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        self.spinner.show(undefined, {
          type: "ball-triangle-path",
          size: "medium",
          bdColor: "rgba(0, 0, 0, 0.8)",
          color: "#fff",
          fullScreen: true,
        });

        self.apiCall
          .put("seller/me/chargebacksAccept", { id: chargeback.id })
          .subscribe(async (data) => {
            if (data.success) {
              this.spinner.hide();
              await this.app.alert("Aviso!", data.message, "info");
              this.ngOnInit();
            } else {
              await this.app.alert("Ops :(", data.message, "error");
            }
          });
      }
    });
  }

  async disputarContestacao(chargeback) {
    const self = this;
    let file = null;
    let { value: fileInput } = await Swal.fire({
      title: "Disputar Contestação",
      html:
        "<p>Ao abrir a disputa, você precisara nos enviar no minimo um dos seguintes documentos abaixo: </p>" +
        '<ul style="text-align: left">' +
        '<li style="margin-bottom: 5px">Cadastro do comprador (nome do titular do cartão, nome cadastrado no site, CPF, email, endereço, telefone fixo ou celular)</li>' +
        '<li style="margin-bottom: 5px">Nota fiscal da compra</li>' +
        '<li style="margin-bottom: 5px">Pedido da compra (print da página do pedido)</li>' +
        '<li style="margin-bottom: 5px">Cópia da Política de Devolução e Troca com aceite eletrônico</li>' +
        '<li style="margin-bottom: 5px">Comprovação de entrega dos Correios e/ou protocolo assinado</li>' +
        '<li style="margin-bottom: 5px">Carta do verdadeiro comprador reconhecendo a despesa</li>' +
        "<p>Caso o documento seja maior que 6MB, é preciso reduzir o tamanho do arquivo de imagem usando um compressor online.</p>" +
        "<p><b>Atenção: Não aceitaremos transcrições de gravações ou conversas de WhatsApp.</b></p>" +
        "<p>Tamanho maximo do arquivo 6MB</p>" +
        "</ul>",
      input: "file",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`,
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Faça upload de uma foto.",
        multiple: "true",
      },
      inputValidator: async (value) => {
        if (!value) {
          return "Você precisa enviar uma foto!";
        }
        // @ts-ignore
        file = value;

        if (file.size > 5000000) {
          return "São permitidos arquivos de até 5MB!";
        }
      },
    });

    if (file) {
      Array.from(file).forEach(async (file) => {
        var reader = new FileReader(); //define a Reader
        reader.onload = async function (f) {
          var file_result = this.result;
          var file_wordArr = CryptoJS.lib.WordArray.create(
            file_result as ArrayBuffer
          );
          var sha1_hash = CryptoJS.SHA1(file_wordArr);
          let sha1 = sha1_hash.toString();
          await self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          const dataUrl = await self.apiCall
            .get("blackbaze/urltoupload")
            .pipe(take(1))
            .toPromise();
          if (!dataUrl.return) {
            await self.spinner.hide();
            return;
          }
          if (
            dataUrl.return &&
            dataUrl.return.authorizationToken &&
            dataUrl.return.uploadUrl &&
            dataUrl.return.uniqid
          ) {
            var optionsB2 = {
              headers: {
                Authorization: dataUrl.return.authorizationToken,
                "Content-Type": file["type"],
                "X-Bz-File-Name":
                  dataUrl.return.uniqid.toString() +
                  "." +
                  file["name"].split("?")[0].split(".").pop(),
                "X-Bz-Content-Sha1": sha1,
              },
            };
            const returnedUpload = await self.api.http
              .post(dataUrl.return.uploadUrl, file, optionsB2)
              .toPromise();
            await self.spinner.hide();
            if (returnedUpload) {
              let arquivo =
                "https://files.wecodde.com/file/wecodde/" +
                JSON.parse(JSON.stringify(returnedUpload)).fileName;
              self.apiCall
                .post("seller/me/chargebacksContest", {
                  picture: arquivo,
                  chargeback: chargeback,
                })
                .subscribe(async (data) => {
                  self.me.refresh().subscribe(async (u) => {
                    self.spinner.hide();
                    if (data.success) {
                      await self.app.alert(
                        "Tudo certo!",
                        "Documentação enviada com sucesso",
                        "success"
                      );
                    } else {
                      await self.app.alert("Ops :(", data.message, "error");
                    }
                    self.ngOnInit();
                  });
                });
            }
          }
        };
        reader.readAsArrayBuffer(file as Blob);
      });
    }
  }

  formatStatus(status) {
    // pending: Pendente
    // accepted_by_client: Acatado pelo cliente
    // accepted_automatically: Acatado Automaticamente
    // contested_by_client: Contestada pelo cliente (ele mandou documentação para contra-contestar o cliente final)
    // waiting_resolution: Aguardando resolução
    // lost: Perdida
    // won: Ganho
    // accepted: Acatado
    // reverted: Revertido

    switch (status) {
      case "pending":
        status = "Pendente";
        break;
      case "accepted_by_client":
        status = "Acatado pelo cliente";
        break;
      case "accepted_automatically":
        status = "Acatado Automaticamente";
        break;
      case "contested_by_client":
        status =
          "Contestada pelo cliente (ele mandou documentação para contra-contestar o cliente final)";
        break;
      case "waiting_resolution":
        status = "Aguardando resolução";
        break;
      case "lost":
        status = "Perdida";
        break;
      case "won":
        status = "Ganho";
        break;
      case "accepted":
        status = "Acatado";
        break;
      case "reverted":
        status = "Revertido";
        break;
    }
    return status;
  }

  async fileToBase64(file) {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  onPeriodChange(period: string): void {
    this.selectedPeriod = period;
    this.loadChartData();
  }

  loadChartData(): void {
    const { startDate, finalDate, granularity } = this.getPeriodDates(
      this.selectedPeriod
    );

    this.getFinancialData(startDate, finalDate, granularity).subscribe(
      (data) => {
        this.chartData = data.return;
        this.financialData = this.chartData.financialData;
        this.productSalesData = this.chartData.productSalesData;
        this.cdr.detectChanges();
      },
      (error) => {
        console.error("Erro ao carregar os dados:", error);
      }
    );
  }

  getPeriodDates(period: string): {
    startDate: Date;
    finalDate: Date;
    granularity: string;
  } {
    const today = this.getDateAtStartOfDay(new Date());
    let startDate: Date;
    let granularity: string;

    if (period === "week") {
      startDate = this.getDateAtStartOfDay(this.getStartOfWeek(today));
      granularity = "daily";
    } else if (period === "month") {
      startDate = this.getDateAtStartOfDay(
        new Date(today.getFullYear(), today.getMonth(), 1)
      );
      granularity = "weekly";
    } else {
      startDate = this.getDateAtStartOfDay(new Date(today.getFullYear(), 0, 1));
      granularity = "monthly";
    }

    return { startDate, finalDate: today, granularity };
  }

  getStartOfWeek(date: Date): Date {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);

    const startOfWeek = new Date(date);
    startOfWeek.setDate(diff);
    startOfWeek.setHours(0, 0, 0, 0);

    return startOfWeek;
  }

  getDateAtStartOfDay(date: Date): Date {
    date.setHours(0, 0, 0, 0);
    return date;
  }

  getFinancialData(
    startDate: Date,
    finalDate: Date,
    granularity: string
  ): Observable<any> {
    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedFinalDate = finalDate.toISOString().split("T")[0];
    const url = `/seller-store/financial/data?startDate=${formattedStartDate}&finalDate=${formattedFinalDate}&granularity=${granularity}`;
    return this.apiCall.get<any>(url);
  }
}
