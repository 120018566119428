<div class="row mt-3">
  <div class="col-12">
    <p class="sub-texto font-size-4 font-size-md-7 m-0">
      Se tem dúvidas sobre como cadastrar seus produtos,
      <button
        type="button"
        class="btn-tutorial btn btn-outline-warning font-size-4 font-size-md-7 text-bold-600 border-0 rounded-0 p-0 d-inline-block"
        (click)="openModal(tutorialVideo)"
      >
        veja o vídeo tutorial.
      </button>
    </p>
  </div>
  <div class="col-12 mt-2">
    <div class="w-100 d-flex justify-content-end">
      <button
        *ngIf="activeTabId === 'on-store'"
        [disabled]="seller?.active != true"
        type="button"
        (click)="openStore()"
        class="btn btn-outline-secondary font-size-5 font-size-md-6 d-flex align-items-center mr-2"
      >
        <i class="ft-eye mr-1 mr-md-2"></i>
        Ver Loja
      </button>
      <button
        *ngIf="activeTabId === 'on-store'"
        type="button"
        (click)="openAddProductsToStore()"
        class="btn btn-warning font-size-4 font-size-md-6 d-flex align-items-center"
      >
        <i class="ft-plus mr-1 mr-md-2"></i>
        Adicionar Produtos
      </button>
      <button
        *ngIf="activeTabId !== 'on-store'"
        type="button"
        (click)="openAddProduct()"
        class="btn btn-warning font-size-4 font-size-md-6 d-flex align-items-center"
      >
        Criar Produto/Serviço
      </button>
    </div>
  </div>
  <div class="col-12 mt-2">
    <div class="card">
      <div class="card-content">
        <div class="card-body p-2 p-md-3">
          <div class="row">
            <div class="col-12">
              <ul
                ngbNav
                [(activeId)]="activeTabId"
                #nav="ngbNav"
                class="nav-tabs flex-grow-1 d-flex justify-content-start border-0"
              >
                <li ngbNavItem [ngbNavItem]="'active'" class="nav-tab-item">
                  <a
                    ngbNavLink
                    [routerLink]="null"
                    [queryParams]="{ tab: 'active' }"
                    class="rounded font-size-4 font-size-md-6"
                  >
                    Ativos
                  </a>
                  <ng-template ngbNavContent>
                    <app-active-and-inactive-products
                      *ngIf="showProducts"
                      [onlyActive]="true"
                    >
                    </app-active-and-inactive-products>
                  </ng-template>
                </li>
                <li ngbNavItem [ngbNavItem]="'inactive'" class="nav-tab-item">
                  <a
                    ngbNavLink
                    [routerLink]="null"
                    [queryParams]="{ tab: 'inactive' }"
                    class="rounded font-size-4 font-size-md-6"
                  >
                    Inativos
                  </a>
                  <ng-template ngbNavContent>
                    <app-active-and-inactive-products
                      *ngIf="showProducts"
                      [onlyActive]="false"
                    >
                    </app-active-and-inactive-products>
                  </ng-template>
                </li>
                <li ngbNavItem [ngbNavItem]="'on-store'" class="nav-tab-item">
                  <a
                    ngbNavLink
                    [routerLink]="null"
                    [queryParams]="{ tab: 'on-store' }"
                    class="rounded font-size-4 font-size-md-6"
                  >
                    Na Loja
                  </a>
                  <ng-template ngbNavContent>
                    <app-on-store-products
                      *ngIf="showProducts"
                    ></app-on-store-products>
                  </ng-template>
                </li>
              </ul>
            </div>
            <div class="col-12">
              <div [ngbNavOutlet]="nav" class="p-0"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #tutorialVideo>
  <div class="embed-container">
    <iframe [src]="tutorialVideoUrl" frameborder="0" allowfullscreen></iframe>
  </div>
</ng-template>
