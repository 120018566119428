<div class="row" style="margin-left: 80px">
  <button class="back-button mt-0" (click)="goBack()">
    <i class="arrow-left"></i>
  </button>
  <p style="font-size: 24px; font-weight: 700">Loja do Personal</p>
</div>

<ul class="products-list">
  <li *ngFor="let product of paginatedProducts" class="products-item">
    <div class="products-content" (click)="toggleDetails(product)">
      <div class="products-image col-auto">
        <img [src]="product.cover" alt="{{ product.name }}" />
      </div>

      <div class="destaque" *ngIf="product.status == true">
        <p>Destaque</p>
      </div>

      <div class="products-details">
        <p class="title">{{ product.name }}</p>
        <p *ngIf="isExpanded(product) || !isMobile">
          {{ product.description }}
        </p>

        <div class="row" *ngFor="let plan of product.Plans">
          <p class="products-price">
            {{ integerToReal(plan.value_cents) }}
          </p>
          <p class="products-cycles">
            / {{ getIntervalTypeInPortuguese(plan.interval_type) }}
          </p>
        </div>

        <div class="products-button" *ngIf="!isMobile || isExpanded(product)">
          <button (click)="openCheckout(product.checkout_url)">Comprar</button>
        </div>
      </div>

      <div *ngIf="isMobile">
        <i
          class="fa"
          [ngClass]="{
            'fa-chevron-right': !isExpanded(product),
            'fa-chevron-down': isExpanded(product)
          }"
          style="color: darkgrey"
          (click)="toggleDetails(product)"
        ></i>
      </div>
    </div>
  </li>
</ul>

<!-- Paginação -->
<ngb-pagination
  *ngIf="totalItems > 0"
  [(page)]="page"
  [pageSize]="pageSize"
  [maxSize]="2"
  [rotate]="true"
  [boundaryLinks]="true"
  size="xl"
  [collectionSize]="totalItems"
  (pageChange)="onPageChange($event)"
  class="pagination"
></ngb-pagination>

<!-- Spinner -->
<ngx-spinner></ngx-spinner>
