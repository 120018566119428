import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { CalendarEvent } from "angular-calendar";
import { PlanStatusType } from "app/personal/pages/treinos/treinos-edit/treinos-edit.types";
import { CustomAdapter } from "app/shared/services/dateAdapter.service";
import { CustomDateParserFormatter } from "app/shared/services/dateParserFormatter.service";
import introJs from "intro.js";
import { IntroStep } from "intro.js/src/core/steps";
import * as moment from "moment";
import { DragulaService } from "ng2-dragula";
import { isSameDay, isSameMonth } from "ngx-bootstrap/chronos";
import { ClipboardService } from "ngx-clipboard";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subject, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import Api from "../helpers/api";
import App from "../helpers/app";
import Me from "../helpers/me";
import { AlunoAvaliacaoComponent } from "./pages/aluno-avaliacao/aluno-avaliacao.component";
import { TrainingWizardComponent } from "app/modules/personal-gpt/training-wizard/training-wizard.component";
import { FreeTestModalComponent } from "app/modules/personal-gpt/free-test-modal/free-test-modal.component";

const colors = {
  red: {
    primary: "#ad2121",
    secondary: "#FAE3E3",
  },
  blue: {
    primary: "#1e90ff",
    secondary: "#D1E8FF",
  },
  yellow: {
    primary: "#e3bc08",
    secondary: "#FDF1BA",
  },
};

@Component({
  selector: "app-aluno",
  templateUrl: "./aluno.component.html",
  styleUrls: ["./aluno.component.scss"],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class AlunoComponent implements OnInit {
  introJS = introJs();

  view: string = "month";
  viewDate: Date = new Date();
  @ViewChild("printbtn") print!: ElementRef<any>;

  modalData: {
    action: string;
    event: CalendarEvent;
  };
  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  date: { year: number; month: number };
  disabled = true;
  activeDayIsOpen: boolean = false;
  isOnTour = false;
  typeTrainingInView = null;
  conjugados = [];
  apiCall = this.api.new().silent();
  BAG = "bag-one";
  subs = new Subscription();
  activeId = 1;
  activeIdTraining = 1;
  pageAv = 1;
  pagePr = 1;
  pageDe = 1;
  pageMu = 1;
  pageTr = 1;
  pageEx = 1;
  pageSize = 10;
  pageSizePr = 10;
  pageSizeDe = 10;
  pageSizeMu = 10;
  pageSizeExec = 5;
  listaExecucoes = [];
  avaliations = [];
  murais = [];
  programas = [];
  desafios = [];
  trainings = [];
  AvaliationTrainings = [];
  relations = [];
  relationsFiltered = [];
  trainingsOriginals = [];
  groupsTrainings = [];
  customer;
  relation;
  isAtivos = true;
  expirationDate: string = null;
  programaDesafioSelecionado = null;
  nomeTreinoPDF = null;
  observacaoPDF = null;
  treinosArquivados = null;
  user = null;
  planStatus: PlanStatusType | null = null;

  tipos = [
    { id: 1, name: "Personalizado" },
    { id: 2, name: "Circuito" },
    { id: 3, name: "Intervalado" },
    { id: 4, name: "Avaliação" },
  ];
  groups = [];

  grupoSelecionado;
  scheduledDate: string;
  dueDate: string;
  minScheduledDate = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date(),
  };

  avaliation = {
    id: null,
    answeredAt: null,
  };
  urlToPlay = null;
  safeURL = null;

  step5Clicked = false;

  dueDateError = false;

  get today() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday());
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private dragulaService: DragulaService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private api: Api,
    private clipboardApi: ClipboardService,
    private _sanitizer: DomSanitizer,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>
  ) {
    this.route.params.subscribe((params) => {
      if (params["destId"]) {
        this.activeId = +params["destId"];
      }
    });

    this.subs.add(
      dragulaService.drag(this.BAG).subscribe(({ el }) => {
        //this.removeClass(el, 'ex-moved');
      })
    );
    this.subs.add(
      dragulaService.drop(this.BAG).subscribe(({ el }) => {
        //this.addClass(el, 'ex-moved');
      })
    );
    this.subs.add(
      dragulaService.over(this.BAG).subscribe(({ el, container }) => {
        //this.addClass(container, 'ex-over');
      })
    );
    this.subs.add(
      dragulaService
        .out(this.BAG)
        .subscribe(({ el, container, name, source }) => {
          //this.removeClass(container, 'ex-over');
        })
    );
  }

  async ngOnInit() {
    const self = this;
    this.listaExecucoes = [];
    self.events = [];
    self.groups = [];
    self.grupoSelecionado;
    self.scheduledDate = this.today;
    self.dueDate = "";
    self.groupsTrainings = [];
    self.programaDesafioSelecionado = null;
    this.listaExercicios = [];
    self.trainings = [];
    self.avaliations = [];
    this.isAtivos = true;

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.me
      .get(false)
      .pipe(take(1))
      .subscribe((user) => {
        self.user = user;
      });

    self.apiCall.get("sellercustomerplataforma").subscribe(async (data) => {
      if (data.return) this.relations = data.return.relations;
    });

    this.apiCall
      .get<PlanStatusType>("seller/me/verify/subscription/iago")
      .subscribe((response) => {
        if (response.success) {
          this.planStatus = response.return;
        }
      });

    const groups = await self.apiCall
      .get("groupcustomers")
      .pipe(take(1))
      .toPromise();
    if (groups.return) self.groups = groups.return;

    self.apiCall.get("grouptrainingall").subscribe(async (cat) => {
      if (cat.return) self.groupsTrainings = cat.return;
      self.spinner.hide();
    });

    this.route.params.subscribe(async (params) => {
      if (params["id"]) {
        const verificacao = await self.apiCall
          .get("seller/validaterelation/" + params["id"])
          .pipe(take(1))
          .toPromise();

        if (!verificacao.return) {
          self.router.navigate(["/page"]);
          return;
        }

        self.apiCall
          .get("avaliations/" + params["id"])
          .subscribe(async (data) => {
            if (data.return) {
              self.avaliations = data.return.avaliations;
              self.customer = data.return.relation.Customer;
              self.relation = data.return.relation;
              const expirationDateSplited = this.formatDate(
                self.relation.expirationDate
              ).split("/");
              self.expirationDate = self.relation.expirationDate
                ? this.dateAdapter.toModel({
                    day: Number(expirationDateSplited[0]),
                    month: Number(expirationDateSplited[1]),
                    year: Number(expirationDateSplited[2]),
                  })
                : null;
            }
            self.spinner.hide();
          });
        self.apiCall.get("mural/" + params["id"]).subscribe(async (data) => {
          if (data.return) {
            self.murais = data.return;
          }
          self.spinner.hide();
        });
        self.apiCall
          .get("grouptrainingplataforma/aluno/" + params["id"])
          .subscribe(async (data) => {
            if (data.return) {
              self.programas = data.return.filter((x) => x.type == "1");
              self.desafios = data.return.filter((x) => x.type == "2");
            }
            self.spinner.hide();
          });
        self.apiCall
          .get("training/personal/" + params["id"])
          .subscribe(async (data) => {
            if (data.return) {
              self.trainingsOriginals = data.return;
              self.treinosArquivados = self.trainingsOriginals.filter(
                (t) => t.status == false && t.type != 4
              );
              for (let t of data.return) {
                if (t.TrainingExecution) {
                  for (let e of t.TrainingExecution) {
                    let feedbackText = "";
                    let observacao = "";
                    if (e.rating != null && e.rating > 0) {
                      let classificacao = self.getFeedback(e.rating);
                      if (classificacao) {
                        feedbackText = "Feedback de execução: " + classificacao;
                      }

                      if (e.observation != null) {
                        observacao = "Observação do Treino: " + e.observation;
                      }
                    }
                    self.events = [
                      ...self.events,
                      {
                        id: t.id,
                        title:
                          (self.customer ? self.customer.name : "") +
                          " executou o treino " +
                          t.name +
                          "  ás " +
                          this.formatHour(e.performedAt) +
                          "h.<br>" +
                          feedbackText +
                          ".<br>" +
                          observacao,
                        start: moment(e.performedAt).toDate(),
                        end: moment(e.performedAt).toDate(),
                        color: {
                          primary: colors.blue.primary,
                          secondary: "white",
                        },
                        allDay: true,
                        draggable: false,
                        resizable: {
                          beforeStart: true,
                          afterEnd: true,
                        },
                      },
                    ];
                  }
                }
              }
            }
            if (!self.lastOption) self.lastOption = 1;
            self.switchTreinos(self.lastOption);
            self.spinner.hide();
          });
      }
    });
  }

  async ngAfterViewInit() {
    const self = this;
    if (history.state.data && history.state.data.isOnTour) {
      self.isOnTour = true;
    }

    if (self.isOnTour) {
      const total = 11;

      const getStepTitleWithCurrentStep = (current: number) =>
        `<div class='w-100 text-center'><span class='d-block grey darken-1 font-size-3 font-size-md-4 text-bold-400'>Passo <b> ${current} </b> de <b> ${total} </b></span></div>`;

      $(document).ready(function () {
        const steps: Partial<IntroStep>[] = [
          {
            element: "#tour-step__5",
            title: getStepTitleWithCurrentStep(5),
            intro:
              "<div class='w-100 text-center'><span class='d-block'>Aqui você pode <b> criar </b> um novo <b> treino </b> em apenas alguns segundos.</span></div>",
            position: "left",
            scrollTo: "tooltip",
          },
          {
            element: "#tour-step__5-mobile",
            title: getStepTitleWithCurrentStep(5),
            intro:
              "<div class='w-100 text-center'><span class='d-block'>Aqui você pode <b> criar </b> um novo <b> treino </b> em apenas alguns segundos.</span></div>",
            position: "left",
            scrollTo: "tooltip",
          },
        ];

        const filteredSteps = steps.filter((obj) => {
          let isElementVisible = true;

          if (obj && obj.element !== null) {
            if ($(obj.element as string).is(":visible") === false) {
              isElementVisible = false;
            }
          }
          return isElementVisible;
        });

        self.introJS.setOptions({
          nextLabel: "Próximo",
          doneLabel: "Concluir!",
          // skipLabel: 'Cancelar',
          prevLabel: "Voltar",
          hideNext: true,
          hidePrev: true,
          overlayOpacity: 0.2,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          steps: filteredSteps,
          showBullets: false,
          showProgress: false,
        });

        if (history.state.data && history.state.data.isOnTour) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });

          let checkExist = setInterval(function () {
            if ($("#tour-step__5").length || $("#tour-step__5-mobile").length) {
              setTimeout(async () => {
                self.spinner.hide();
                self.isOnTour = true;
                self.introJS.start();
              }, 2500);

              clearInterval(checkExist);
            }
          }, 100);
        }
      });

      this.introJS.onexit(function () {
        self.isOnTour = false;
      });

      this.introJS.onbeforechange(async function (targetElement) {
        $(document).ready(() => {
          $(".introjs-skipbutton").show();
          $(".introjs-nextbutton").css({
            background: "#40C057",
            color: "white",
            "text-shadow": "none",
            border: "1px solid rgb(58 173 79)",
          });
          $(".introjs-nextbutton").show();
          $(".introjs-prevbutton").hide();

          const nextBtn = document.getElementsByClassName(
            "introjs-nextbutton"
          )?.[0] as HTMLButtonElement;

          if (nextBtn) {
            if (this._currentStep === 0) {
              nextBtn.onclick = () => {
                if (targetElement) {
                  targetElement.click();

                  $("#tour-step__6").ready(() => {
                    this.addStep({
                      element: "#tour-step__6",
                      title: getStepTitleWithCurrentStep(6),
                      intro:
                        "<div class='w-100 text-center'><span class='d-block'>Agora, vamos te mostrar como criar treinos na <b> forma tradicional.</b></span></div>",
                      scrollTo: "tooltip",
                    })
                      .refresh(true)
                      .nextStep();
                  });
                }
              };
            }

            if (this._currentStep === 1) {
              nextBtn.onclick = function () {
                self.modalService.dismissAll();
                self.openTreino(null, true);
              };
            }
          }
        });

        return true;
      });
    }
  }

  getNameOfTypeTraing(type) {
    return this.tipos.find((t) => t.id == type).name;
  }

  OpenModalXl(content) {
    this.modalService.open(content, { centered: true, size: "xl" });
  }

  OpenPersonalGPTModal() {
    if (this.planStatus?.plano_ativo && this.planStatus.plano_em_dia) {
      const beforeDismiss = async () => {
        return await this.app
          .confirm(
            "Atenção",
            "Você tem certeza que deseja sair? As alterações não salvas serão perdidas!"
          )
          .then((condition) => !!condition);
      };

      const modalRef = this.modalService.open(TrainingWizardComponent, {
        centered: true,
        size: "xl",
        beforeDismiss,
      });
      const instance = modalRef.componentInstance as
        | TrainingWizardComponent
        | undefined;

      if (instance) {
        instance.customer = this.customer;
        instance.oldTrainningsRaw = this.trainingsOriginals;
        instance.modalRef = modalRef;
        instance.relation = this.relation;
      }
    } else if (this.planStatus?.hasTestedIago === false) {
      const modalRef = this.modalService.open(FreeTestModalComponent, {
        centered: true,
      });

      const instance = modalRef.componentInstance as
        | FreeTestModalComponent
        | undefined;

      if (instance) {
        instance.modalRef = modalRef;
      }
    } else {
      window.open("https://opersonaldigital.com.br/pv-iago/", "_blank");
    }
  }

  viewStoreLink(content) {
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  openCreationTypeModal(training: any = null, canEdit: boolean, content: any) {
    if (this.user) {
      this.modalService.open(content, { centered: true });
    } else {
      this.openTreino(training, canEdit);
    }
  }

  closeDay() {
    this.activeDayIsOpen = false;
  }

  handleEvent(action: string, event: CalendarEvent): void {}

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  getFeedback(rating) {
    let retorno = "";
    if (rating == "1") {
      retorno = "Leve";
    } else if (rating == "2") {
      retorno = "Muito Leve";
    } else if (rating == "3") {
      retorno = "Ótimo";
    } else if (rating == "4") {
      retorno = "Pesado";
    } else if (rating == "5") {
      retorno = "Muito Pesado";
    }
    return retorno;
  }
  reorderTreinos() {
    const self = this;
    if (this.isAtivos) {
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      for (let t of this.trainings) {
        t.order = this.trainings.indexOf(t) + 1;
      }
      self.apiCall
        .put("training/personal/" + this.relation.id + "/order", {
          trainings: this.trainings,
        })
        .subscribe(async (data) => {
          self.spinner.hide();
          await self.ngOnInit();
          if (data.success) {
            this.toastr.success(
              "Treinos reordenados com sucesso!",
              "Tudo Certo!"
            );
          } else {
            this.toastr.error(data.message, "Ops :(");
          }
        });
    }
  }
  exibeListaExecucoes(execucoes, content) {
    execucoes = execucoes.sort(function (a, b) {
      const aDate = new Date(a.performedAt);
      const bDate = new Date(b.performedAt);
      return bDate.getTime() - aDate.getTime();
    });
    this.listaExecucoes = execucoes;
    this.modalService.open(content, { centered: true });
  }
  lastOption = null;
  switchTreinos(option) {
    const self = this;
    self.trainings = [];
    self.lastOption = option;
    if (option == 1) {
      self.trainings = self.trainingsOriginals.filter(
        (t) => t.status == true && t.type != 4
      );
      self.trainings.sort(function (a, b) {
        return a.order - b.order;
      });
    } else if (option == 2) {
      self.trainings = self.trainingsOriginals.filter(
        (t) => t.status == false && t.type != 4
      );
    } else if (option == 3) {
      self.trainings = self.trainingsOriginals.filter((t) => t.type == 4);
      self.trainings.sort(function (a, b) {
        return a.createdAt - b.createdAt;
      });
    }
  }
  listaExercicios = [];

  getNameExercise(e) {
    if (!e.conjugado) {
      return "";
    }
    let retorno = "";
    const searching = this.listaExercicios.find((x) => x.id == e.conjugado);
    if (searching) {
      retorno = "Conjugado com " + searching.name;
    }
    return retorno;
  }
  viewExercicios(training, content) {
    this.typeTrainingInView = training.type;
    this.listaExercicios = training.TrainingExercises.sort(function (a, b) {
      return a.order - b.order;
    });

    this.modalService.open(content, { centered: true, size: "lg" });
  }

  async printExercicios(training) {
    this.typeTrainingInView = training.type;
    this.listaExercicios = training.TrainingExercises.sort(function (a, b) {
      return a.order - b.order;
    });

    this.nomeTreinoPDF = training.name;
    this.observacaoPDF = training.note ? training.note : null;

    setTimeout(() => {
      this.print.nativeElement.click();
    }, 200);
  }

  switchTreino(event, training) {
    const self = this;
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    if (training && training.id && this.relation && this.relation.id) {
      self.apiCall
        .put("training/personal/" + this.relation.id + "/status", {
          training: {
            id: training.id,
            status: event,
            vencimento: moment(training.vencimento).isAfter(moment())
              ? training.vencimento
              : null,
          },
        })
        .subscribe(async (data) => {
          self.spinner.hide();
          await self.ngOnInit();
          if (data.success) {
            this.toastr.success(
              event
                ? "Treino ativado com sucesso"
                : "Treino arquivado com sucesso",
              "Tudo Certo!"
            );
          } else {
            this.toastr.error(data.message, "Ops :(");
          }
        });
    }
  }
  formatDate(date) {
    return moment(date).format("DD/MM/YYYY");
  }
  formatHour(date) {
    moment.locale("pt-br");
    return moment(date).format("LT");
  }

  deleteAvaliation(avaliation) {
    const self = this;

    self.app
      .confirm("Tem certeza?", "Você realmente deseja deletar esta avaliação?")
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall
            .delete("avaliations/" + self.relation.id + "/" + avaliation.id)
            .subscribe(async (data) => {
              self.spinner.hide();
              if (data.success) {
                self.ngOnInit();

                this.toastr.success(
                  "Avaliação deletada com sucesso",
                  "Tudo Certo!"
                );
              } else {
                this.toastr.error(data.message, "Ops :(");
              }
            });
        }
      });
  }

  resetAvaliation(avaliation) {
    const self = this;
    avaliation.answeredAt = null;
    avaliation.type = "0";

    self.app
      .confirm("Tem certeza?", "Você realmente deseja resetar esta avaliação?")
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });

          let chamada;
          let body = {
            avaliation: avaliation,
          };

          chamada = self.apiCall.put(
            "resetavaliations/" + self.relation.id,
            body
          );

          chamada.subscribe(async (data) => {
            if (data.success) {
              this.toastr.success(
                "Avaliação resetada com sucesso",
                "Tudo Certo!"
              );
              self.spinner.hide();
            } else {
              this.toastr.error(data.message, "Ops :(");
              self.spinner.hide();
            }
          });
        }
      });
  }

  openAvaliacao(avaliation, canEdit) {
    const modalRef = this.modalService.open(AlunoAvaliacaoComponent, {
      size: "lg",
      backdrop: true,
      keyboard: false,
    });
    modalRef.componentInstance.relation = this.relation;
    if (avaliation) modalRef.componentInstance.avaliation = avaliation;
    modalRef.componentInstance.canEdit = canEdit;

    modalRef.result.then(
      (data) => {
        // on close
        this.ngOnInit();
      },
      (reason) => {
        // on dismiss
      }
    );
  }

  openTreino(training, canEdit) {
    let isOnTour = Boolean(this.isOnTour);

    if (this.introJS) this.introJS.exit(true);

    const data = {
      training: training && training.id ? training.id : null,
      isOnTour: isOnTour,
      canEdit: canEdit,
      relation: this.relation.id,
    };
    const encodedData = btoa(JSON.stringify(data))
      .replace("==", "")
      .replace("=", "");

    if (this.user && training && training?.editorVersion === "iago_v1") {
      this.router.navigate([`/page/personal/treinos/${encodedData}/editar`]);
    } else {
      this.router.navigate(["/page/treino/details/" + encodedData]);
    }
  }

  openHistoricoCarga(training) {
    let isOnTour = Boolean(this.isOnTour);

    if (this.introJS) this.introJS.exit(true);
    const data = {
      training: training && training.id ? training.id : null,
      isOnTour: isOnTour,
      relation: this.relation.id,
    };
    const encodedData = btoa(JSON.stringify(data))
      .replace("==", "")
      .replace("=", "");

    this.router.navigate(["/page/treino/historico-carga/" + encodedData]);
  }

  deletarTreino(training) {
    const self = this;
    self.app
      .confirm("Tem certeza?", "Você realmente deseja deletar este treino?")
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall
            .delete("training/personal/" + self.relation.id + "/" + training.id)
            .subscribe(async (data) => {
              self.spinner.hide();
              if (data.success) {
                self.ngOnInit();

                this.toastr.success(
                  "Treino deletado com sucesso",
                  "Tudo Certo!"
                );
              } else {
                this.toastr.error(data.message, "Ops :(");
              }
            });
        }
      });
  }
  atualizarAnotacoes() {
    const self = this;
    if (!self.relation.id) {
      return;
    }
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    self.apiCall
      .put("note/sellercustomer", { relation: self.relation })
      .subscribe(async (data) => {
        self.spinner.hide();
        if (data.success) {
          self.ngOnInit();
          this.toastr.success(
            "Anotações atualizadas com sucesso",
            "Tudo Certo!"
          );
        } else {
          this.toastr.error(data.message, "Ops :(");
        }
      });
  }

  duplicarAvaliacao(avaliation) {
    const self = this;
    self.app
      .confirm("Confirmação", "Você deseja duplicar esta avaliação?")
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall
            .post("avaliation/duplicate", { avaliation: avaliation })
            .subscribe(async (data) => {
              self.spinner.hide();
              if (data.success) {
                self.ngOnInit();
                this.toastr.success(
                  "Avaliação duplicada com sucesso",
                  "Tudo Certo!"
                );
              } else {
                this.toastr.error(data.message, "Ops :(");
              }
            });
        }
      });
  }
  alunosSelecionadosParaExportarAvaliacao = [];
  avaliationToExport = null;
  exportandoAvaliacaoAlunos = false;
  async exportarAvaliacaoAluno(avaliation, modal) {
    this.exportandoAvaliacaoAlunos = true;

    this.grupoSelecionado = null;
    this.avaliationToExport = avaliation;
    if (this.relations && this.relation) {
      this.relationsFiltered = this.relations.filter(
        (r) => r.id != this.relation.id
      );
    }
    this.modalService.open(modal, { centered: true });
  }
  copiarAlunosGrupoExpAvaliacao() {
    if (!this.grupoSelecionado || !this.grupoSelecionado.CustomersGroupsUsers) {
      return;
    }
    for (let t of this.grupoSelecionado.CustomersGroupsUsers) {
      const found = this.relations.find((x) => x.id == t.relation);
      if (found) {
        let verify = null;
        if (
          this.alunosSelecionadosParaExportarAvaliacao &&
          this.alunosSelecionadosParaExportarAvaliacao.length > 0
        ) {
          this.alunosSelecionadosParaExportarAvaliacao.find(
            (x) => x.id == found.id
          );
        }
        if (!verify) {
          this.alunosSelecionadosParaExportarAvaliacao.push(found);
        }
      }
    }
    this.alunosSelecionadosParaExportarAvaliacao = [
      //@ts-ignore
      ...this.alunosSelecionadosParaExportarAvaliacao,
    ];
  }

  copiarAlunosGrupoExpTreino() {
    if (!this.grupoSelecionado || !this.grupoSelecionado.CustomersGroupsUsers) {
      return;
    }
    for (let t of this.grupoSelecionado.CustomersGroupsUsers) {
      const found = this.relations.find((x) => x.id == t.relation);
      if (found) {
        let verify = null;
        if (
          this.alunosSelecionadosParaExportar &&
          this.alunosSelecionadosParaExportar.length > 0
        ) {
          this.alunosSelecionadosParaExportar.find((x) => x.id == found.id);
        }
        if (!verify) {
          this.alunosSelecionadosParaExportar.push(found);
        }
      }
    }
    this.alunosSelecionadosParaExportar = [
      ...this.alunosSelecionadosParaExportar,
    ];
  }

  async cancelaExportacaoAvaliacaoAlunos(modal) {
    this.exportandoAvaliacaoAlunos = false;
    this.avaliationToExport = null;
    this.alunosSelecionadosParaExportarAvaliacao = [];

    this.grupoSelecionado = null;
    modal.close("Close click");
  }

  async exportarAvaliacaoParaAlunos(modal) {
    const self = this;
    if (self.alunosSelecionadosParaExportarAvaliacao.length > 7) {
      const confirmacao = await self.app.confirm(
        "Tem certeza?",
        "Exportar para mais de 7 alunos pode levar até 1 hora. Para exportar imediatamente exporte para até 7 alunos. Deseja continuar com a exportação para todos os alunos?"
      );
      if (!confirmacao) {
        return;
      }
    }
    if (
      self.avaliationToExport &&
      self.alunosSelecionadosParaExportarAvaliacao &&
      self.alunosSelecionadosParaExportarAvaliacao.length > 0
    ) {
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      self.apiCall
        .post("avaliation/export/customer", {
          avaliation: self.avaliationToExport,
          relations: self.alunosSelecionadosParaExportarAvaliacao,
        })
        .subscribe(async (data) => {
          self.spinner.hide();
          if (data.success) {
            await this.cancelaExportacaoAvaliacaoAlunos(modal);
            self.ngOnInit();

            this.toastr.success(data.message, "Tudo Certo!");
          } else {
            this.toastr.error(data.message, "Ops :(");
          }
        });
    }
  }
  exportarAvaliacaoBanco(avaliation) {
    const self = this;
    self.app
      .confirm(
        "Confirmação",
        "Você deseja exportar esta avaliação para o banco de avaliações?"
      )
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall
            .post("avaliation/export/model", { avaliation: avaliation })
            .subscribe(async (data) => {
              self.spinner.hide();
              if (data.success) {
                self.ngOnInit();
                this.toastr.success(
                  "Avaliação exportada com sucesso",
                  "Tudo Certo!"
                );
              } else {
                this.toastr.error(data.message, "Ops :(");
              }
            });
        }
      });
  }
  duplicarTreino(training) {
    const self = this;
    self.app
      .confirm("Confirmação", "Você deseja duplicar este treino?")
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          training.isModel = false;
          training.isExperimental = false;
          self.apiCall
            .post("training/duplicate", { training: training })
            .subscribe(async (data) => {
              self.spinner.hide();
              if (data.success) {
                self.ngOnInit();
                this.toastr.success(
                  "Treino duplicado com sucesso",
                  "Tudo Certo!"
                );
              } else {
                this.toastr.error(data.message, "Ops :(");
              }
            });
        }
      });
  }
  alunosSelecionadosParaExportar = [];
  trainingToExport = null;
  exportandoAlunos = false;
  async exportarTreinoAluno(training, modal) {
    this.exportandoAlunos = true;
    this.trainingToExport = training;

    this.grupoSelecionado = null;
    if (this.relations && this.relation) {
      this.relationsFiltered = this.relations.filter(
        (r) => r.id != this.relation.id
      );
    }
    this.modalService.open(modal, { centered: true });
  }

  async exportarTreinoPrograma(training, modal) {
    this.trainingToExport = training;
    this.programaDesafioSelecionado = null;
    this.modalService.open(modal, { centered: true });
  }

  async cancelaExportacaoAlunos(modal) {
    this.exportandoAlunos = false;
    this.scheduledDate = this.today;
    this.dueDate = "";
    this.dueDateError = false;
    this.trainingToExport = null;
    this.grupoSelecionado = null;
    this.alunosSelecionadosParaExportar = [];
    modal.close("Close click");
  }

  async cancelaExportacaoProgramas(modal) {
    this.exportandoAlunos = false;
    this.programaDesafioSelecionado = null;
    modal.close("Close click");
  }

  async exportarAlunos(modal) {
    const self = this;

    const scheduledDate = moment(self.scheduledDate, "DD/MM/YYYY");
    const dueDate = self.dueDate ? moment(self.dueDate, "DD/MM/YYYY") : null;

    self.dueDateError = false;
    if (dueDate && dueDate.isBefore(scheduledDate)) {
      self.toastr.error(
        "Data de vencimento não pode ser anterior a data de agendamento",
        "Ops :("
      );
      self.dueDateError = true;
      return;
    }

    if (self.alunosSelecionadosParaExportar.length > 5) {
      const confirmacao = await self.app.confirm(
        "Tem certeza?",
        "Exportar mais de 5 alunos pode levar até 1 hora. Se desejar, você pode optar por exportar um aluno de cada vez. Deseja continuar com a exportação de todos os alunos?"
      );
      if (!confirmacao) {
        return;
      }
    }
    if (
      self.scheduledDate &&
      self.trainingToExport &&
      self.alunosSelecionadosParaExportar &&
      self.alunosSelecionadosParaExportar.length > 0
    ) {
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      self.apiCall
        .post("training/export/customer", {
          training: {
            ...self.trainingToExport,
            agendamento: moment(self.scheduledDate, "DD/MM/YYYY").toISOString(),
            vencimento: self.dueDate
              ? moment(self.dueDate, "DD/MM/YYYY").toISOString()
              : null,
          },
          relations: self.alunosSelecionadosParaExportar,
        })
        .subscribe(async (data) => {
          self.spinner.hide();
          if (data.success) {
            await this.cancelaExportacaoAlunos(modal);
            self.ngOnInit();
            this.toastr.success(data.message, "Tudo Certo!");
          } else {
            this.toastr.error(data.message, "Ops :(");
          }
        });
    }
  }

  async exportarProgramasDesafios(modal) {
    const self = this;

    if (self.trainingToExport && self.programaDesafioSelecionado) {
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      self.apiCall
        .post("training/export/grouptraining", {
          training: self.trainingToExport,
          groupTraining: self.programaDesafioSelecionado,
        })
        .subscribe(async (data) => {
          self.spinner.hide();
          if (data.success) {
            await this.cancelaExportacaoAlunos(modal);
            self.ngOnInit();
            this.toastr.success("Treino exportado com sucesso", "Tudo Certo!");
          } else {
            this.toastr.error(data.message, "Ops :(");
          }
        });
    }
  }

  exportarTreinoBanco(training) {
    const self = this;
    self.app
      .confirm(
        "Confirmação",
        "Você deseja exportar este treino para o banco de treinos?"
      )
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall
            .post("training/export/model", { training: training })
            .subscribe(async (data) => {
              self.spinner.hide();
              if (data.success) {
                self.ngOnInit();
                this.toastr.success(
                  "Treino exportado com sucesso",
                  "Tudo Certo!"
                );
              } else {
                this.toastr.error(data.message, "Ops :(");
              }
            });
        }
      });
  }

  atualizarExpirationDate() {
    const self = this;

    self.relation.expirationDate = self.expirationDate
      ? moment(self.expirationDate, "DDMMYYYY").toDate()
      : null;
    if (!self.relation.id) {
      return;
    }
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    self.apiCall
      .put("expirationdate/sellercustomer", { relation: self.relation })
      .subscribe(async (data) => {
        self.spinner.hide();
        if (data.success) {
          self.ngOnInit();
          this.toastr.success(
            "Anotações atualizadas com sucesso",
            "Tudo Certo!"
          );
        } else {
          this.toastr.error(data.message, "Ops :(");
        }
      });
  }

  linkPdfGerado = null;
  gerarLinkPdf(training, modal) {
    const self = this;

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    self.apiCall.get("pdf/" + training.id).subscribe(async (data) => {
      await self.spinner.hide();
      if (data.success) {
        this.linkPdfGerado = data.return;
        this.clipboardApi.copyFromContent(this.linkPdfGerado);
        this.modalService.open(modal, { centered: true });
      } else {
        this.toastr.error(data.message, "Ops :(");
      }
    });
  }

  gerarLinkPdfAvaliation(avaliation, modal) {
    const self = this;

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    self.apiCall
      .get("avaliation/pdf/" + avaliation.id)
      .subscribe(async (data) => {
        await self.spinner.hide();
        if (data.success) {
          this.linkPdfGerado = data.return;
          window.open(this.linkPdfGerado, "_blank");
        } else {
          this.toastr.error(data.message, "Ops :(");
        }
      });
  }

  copiarLinkPdf() {
    const self = this;
    this.clipboardApi.copyFromContent(this.linkPdfGerado);
  }

  openWhatsapp(linkPdf) {
    let phoneFormated = null;
    if (this.customer.phone) {
      phoneFormated = this.customer.phone.replace("+", "");
    }
    if (phoneFormated.length == 11) {
      phoneFormated = phoneFormated.padStart(13, "55");
    }
    window.open(
      `https://api.whatsapp.com/send?phone=${phoneFormated}&text=*Seu treino está pronto!* Acesse o link e baixe o pdf: ${linkPdf}`,
      "_blank"
    );
  }

  openPDF(linkPdf) {
    window.open(linkPdf, "_blank");
  }

  switchAlunoGroup(event, group, groupTrainingCustomer) {
    const self = this;
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    if (group) {
      self.apiCall
        .put("grouptraining/customer", {
          groupCustomer: {
            id: groupTrainingCustomer,
            group: group,
            status: event,
          },
        })
        .subscribe(async (data) => {
          self.spinner.hide();
          await self.ngOnInit();
          if (data.success) {
            this.toastr.success(
              event
                ? "Aluno ativado para este programa"
                : "Aluno inativado para este programa",
              "Tudo Certo!"
            );
          } else {
            this.toastr.error(data.message, "Ops :(");
          }
        });
    }
  }

  onSelectAll() {
    this.alunosSelecionadosParaExportarAvaliacao = this.relations;
  }

  viewMidia(urlToPlay, content) {
    this.urlToPlay = urlToPlay;
    if (urlToPlay.startsWith("https://www.youtube.com/watch?")) {
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
        urlToPlay.replace(
          "https://www.youtube.com/watch?v=",
          "https://www.youtube.com/embed/"
        )
      );
    } else if (urlToPlay.startsWith("http://www.youtube.com/watch?")) {
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
        urlToPlay.replace(
          "http://www.youtube.com/watch?v=",
          "https://www.youtube.com/embed/"
        )
      );
    }
    this.modalService.open(content, { centered: true, size: "lg" });
  }
}
