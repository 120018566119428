<div class="container" style="background: #ffffff; padding: 0px">
  <div class="card fixed-sidebar">
    <img [src]="seller?.banner" alt="logo personal" />
    <img
      [src]="
        userUrl && userUrl.photo_url
          ? userUrl.photo_url
          : '../../../../assets/img/portrait/small/no-avatar.svg'
      "
      class="user-profile-image"
      alt="User Profile Image"
      height="140"
      width="140"
    />
    <div class="card-body">
      <p class="card-text">{{ seller?.name }}</p>
      <p class="card-subtext" *ngIf="isMobile">{{ displayText }}</p>
      <p *ngIf="!isMobile" class="card-subtext">{{ seller?.description }}</p>
      <button
        *ngIf="seller?.description.length > maxChars && isMobile"
        (click)="toggleExpand()"
        class="toggle-button"
      >
        {{ isExpanded ? "Mostrar menos" : "Mostrar mais" }}
        <span>{{ isExpanded ? "▲" : "▼" }}</span>
      </button>
    </div>
    <div *ngIf="seller && seller.SellerStoreContact">
      <label class="contact" *ngIf="!isMobile">Como me encontrar</label>
      <div
        class="contact-icons row"
        style="justify-content: center; margin-top: 10px; margin-bottom: 10px"
      >
        <div *ngFor="let contact of seller.SellerStoreContact">
          <button
            type="button"
            *ngIf="contact.contactTypeId === 1"
            (click)="openInstagram(contact.contact)"
          >
            <i class="fa fa-instagram" [style.color]="selectedColor"></i>
          </button>
          <button
            type="button"
            *ngIf="contact.contactTypeId === 2"
            (click)="openWhatsApp(contact.contact)"
          >
            <i class="fa fa-whatsapp" [style.color]="selectedColor"></i>
          </button>
          <button
            type="button"
            *ngIf="contact.contactTypeId === 3"
            (click)="openEmail(contact.contact)"
          >
            <i class="fa fa-envelope-o" [style.color]="selectedColor"></i>
          </button>

          <button
            type="button"
            *ngIf="contact.contactTypeId === 4"
            (click)="openEmail(contact.contact)"
          >
            <i class="fa fa-globe" [style.color]="selectedColor"></i>
          </button>

          <button
            type="button"
            *ngIf="contact.contactTypeId === 5"
            (click)="openEmail(contact.contact)"
          >
            <i class="fa fa-heart" [style.color]="selectedColor"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
