import { Component, OnInit } from '@angular/core';
import * as cep from "cep-promise";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AuthService } from "../shared/auth/auth.service";
import { NgxSpinnerService } from "ngx-spinner";
import Me from "../helpers/me";
import { SocialAuthService } from "angularx-social-login";
import App from "../helpers/app";
import Api from "../helpers/api";
import { take } from "rxjs/operators";
import * as moment from "moment";
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { ReCaptchaV3Service } from 'ng-recaptcha';

declare var Iugu: any;
declare let fbq: Function;
declare let gtag: Function;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout-marketplace.component.html',
  styleUrls: ['./checkout-marketplace.component.scss']
})
export class CheckoutMarketplaceComponent implements OnInit {
  apiCall = this.api.new().silent();
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  hidecupom = false

  formasdePagamento = []
  formas = [
    {
      "id": "credit_card",
      "label": "Cartão de Crédito"
    },
    {
      "id": "bank_slip",
      "label": "Boleto"
    },
    {
      "id": "pix",
      "label": "PIX"
    }
  ]
  formasFiltered = []
  novoCartao = {
    number: null,
    cvv: null,
    date: null,
    name: null
  }
  usuario = {
    nome: null,
    email: null,
    phone: null,
    senha: null,
  }

  usuario_email
  usuario_phone
  perdeuFocoEmail = false

  cupomDigitado
  cupomRetornado

  endereco = {
    cpf_cnpj: null,
    zip_code: null,
    number: null,
    street: null,
    city: null,
    state: null,
    district: null,
  }
  userToLogin = {
    email: null,
    senha: null,
  }
  user = null
  planoParam = null
  planoEscolhido = null
  logged = false
  formaEscolhida = null
  erroCartao = null
  utmSource = null
  utmMedium = null
  utmCampaing = null
  utmContent = null
  ipAddress = null
  fbpCookie = null
  userAgent = null
  product_id = null
  product = null
  product00 = null
  estados = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO']
  eventID = null
  promo1 = false
  promo2 = false
  promo3 = false
  newUser = false
  acceptTerms = false
  venceuDiasGratuitos = false
  seller = null
  sellerAccountID = null
  sellerAccountID2 = null
  personal = {
    name: null,
    email: null,
    phone: null
  }
  sellerId = null
  planPriceFb = null
  isEmailPersonal = false
  productImg = "https://files.wecodde.com/file/wecodde/vector-web-laranja.png"
  constructor(private modalService: NgbModal,
    private router: Router,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private api: Api,
    public cookieService: CookieService,
    private http: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service) {
    //Iugu.setAccountID("99F1C1F551CE48EFB3148F0F02149F90")    


    this.route.params.subscribe((params) => {
      if (params['product']) {

        let checkParams = JSON.parse(atob(params['product']))
        console.log(checkParams)

        if (checkParams && checkParams.productId && checkParams.sellerAccount) {
          this.product_id = checkParams.productId
          Iugu.setAccountID(checkParams.sellerAccount)
        }
      }


    })

    this.route.queryParams.subscribe((qParams) => {
      if (qParams['seller']) {
        console.log("Seller", qParams['seller'])
      }
      if (qParams['sellerID']) {
        this.sellerId = qParams['sellerID']
      }
    })
  }


  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  async ngOnInit() {
    const self = this
    self.cupomRetornado = null

    this.fbpCookie = this.cookieService.get('_fbp');

    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    console.log("idd", this.product_id)

    const product = await self.apiCall.get('customer/me/getproduct/' + this.product_id).pipe(take(1)).toPromise()

    if (product.return) {
      console.log(product.return)
      this.product = product.return
      this.product.payable_with = JSON.parse(this.product.payable_with)

      this.seller = this.product.user

      const selleraccount = await self.apiCall.get('customer/me/getselleraccount/' + this.product.user).pipe(take(1)).toPromise()

      if (selleraccount.return) {
        this.sellerAccountID = selleraccount.return.account_id
        console.log(this.sellerAccountID)

        Object.assign(this.personal, {
          email: selleraccount.return.User.email,
          name: selleraccount.return.person_name,
          phone: selleraccount.return.telephone
        });
        
      }

      if (this.product.payable_with && this.product.payable_with.filter(x => x.id == 'all').length > 0) {
        this.formasdePagamento = this.formas
      } else {
        this.formasdePagamento = this.product.payable_with
      }

      self.formaEscolhida = this.formasdePagamento[0]

      if (this.product.plan_type == 1) {
        this.planoEscolhido = this.product.Plans[0]
        this.planoEscolhido.price = 'R$ ' + (this.product.Plans[0].value_cents / 100).toFixed(2).toString().replace('.', ',')
      } else {
        this.planoEscolhido = this.product
        this.planoEscolhido.price = 'R$ ' + (this.product.value_cents / 100).toFixed(2).toString().replace('.', ',')
        this.planoEscolhido.max_cycles = 1
      }

      if (this.sellerId && this.planoEscolhido && this.planoEscolhido.value_cents) {
        this.planPriceFb = (this.planoEscolhido.value_cents * this.planoEscolhido.interval) / 100
      }

      console.log("Preço", this.planPriceFb)

      console.log("Plano", this.planoEscolhido)
      console.log("Product", this.product)
      console.log("sellerID", this.sellerId)
      console.log("Forma", this.formaEscolhida)
    }

    const user = await self.me.get().pipe(take(1)).toPromise()
    self.user = user

    console.log("User", self.user)

    if (self.user) {

      self.usuario_email = self.user.email
      console.log("self.usuario_email", self.usuario_email)
      await self.verificaEmail()
      self.perdeuFocoEmail = true
      self.userToLogin.senha = JSON.parse(JSON.stringify(user)).password
      // await self.login()
    }


    if (this.fbpCookie == "") {
      this.fbpCookie = null
    }

    if (this.sellerId) {
      this.iniciaCheckoutFb()
    }


    self.spinner.hide()

  }


  iniciaCheckoutFb() {
    this.generateEventID()
    fbq('init', '544083090982331')
    fbq('trackSingle', '544083090982331', 'PageView', {}, { eventID: this.eventID });
    this.sendFbEventMVP('PageView', null);
    fbq('trackSingle', '544083090982331', 'InitiateCheckout', {}, { eventID: this.eventID });
    this.sendFbEventMVP('InitiateCheckout', null);
  }

  async generateEventID() {
    const min = Math.ceil(1000000);
    const max = Math.floor(1000000000);
    this.eventID = "FBPL" + Math.floor(Math.random() * (max - min + 1)) + min;
  }

  generateRandomString() {
    let code = Math.random()
      .toString(36)
      .substr(2, 4)
      .toUpperCase()

    return code
  }

  ngAfterViewInit() {
    const self = this

    self.formaEscolhida = self.formas.find(x => x.id == 'credit_card')
  }
  isEmailInvalido = false
  isEmailCadastrado = false
  async verificaEmail() {
    const self = this
    self.isEmailPersonal = false
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    self.reinicializaVariaveis()
    self.usuario.email = self.usuario_email
    if (self.usuario_email) {
      const verificacao = await self.apiCall.get('customer/me/verificacao-email/' + self.usuario_email).pipe(take(1)).toPromise()
      console.log("verificacao", verificacao)
      if (verificacao.return && verificacao.return.existente == true) {
        self.isEmailCadastrado = true
        self.userToLogin.email = self.usuario_email
        if (verificacao.return.user) {
          console.log("verificacao.return.user", verificacao.return.user)
          self.usuario.nome = verificacao.return.user.name
          self.usuario_phone = verificacao.return.user.phone
          self.usuario.phone = self.usuario_phone
        } else if (verificacao.return.isPersonal) {
          self.isEmailPersonal = true
        }
      }
      else {
        // self.usuario.email = self.usuario_email
      }
      if (!self.validateEmail(self.usuario_email) || self.usuario_email.includes('.com.com')) {
        self.isEmailInvalido = true
      }
    }
    self.spinner.hide();

    self.disableAssinar();

  }

  async verificaPhone() {
    const self = this
    if (self.isEmailCadastrado == false) {
      self.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      // self.reinicializaVariaveis()
      self.usuario.phone = self.usuario_phone
      if (self.usuario_phone) {
        const verificacao = await self.apiCall.get('customer/me/verificacao-phone/' + self.usuario_phone.e164Number).pipe(take(1)).toPromise()
        if (verificacao.return && verificacao.return.existente == true) {
          if (verificacao.return.user) {
            self.usuario.nome = verificacao.return.user.name
            self.usuario_email = verificacao.return.user.email
          }
        }
        else {
          self.usuario.phone = self.usuario_phone.e164Number
        }
      }
      self.spinner.hide();
    }

  }


  cupomfbprice = null

  reinicializaVariaveis() {
    const self = this
    self.user = null
    self.userToLogin = {
      email: null,
      senha: null,
    }
    self.usuario = {
      nome: null,
      email: null,
      phone: null,
      senha: null,
    }
    self.isEmailCadastrado = false
    self.isEmailInvalido = false
  }
  priceToShow() {
    const self = this
    if (self.cupomRetornado) {
      let retorno = ''
      if (self.planoEscolhido.identifier.toLowerCase().includes('boleto')) {
        if (self.cupomRetornado.percentageFirstMonth != null) {
          let aux = self.planoEscolhido.pfb - (self.planoEscolhido.pfbaux * self.cupomRetornado.percentageFirstMonth / 100)
          aux = Math.round((aux + Number.EPSILON) * 100) / 100
          self.cupomfbprice = aux
          if (aux != null) {
            retorno = 'R$ ' + aux.toFixed(2).toString().replace('.', ',') + self.planoEscolhido.labelaux
          }
        } else if (self.cupomRetornado.priceFirstMonth != null) {
          let aux = self.planoEscolhido.pfb - self.planoEscolhido.pfbaux + self.cupomRetornado.priceFirstMonth
          aux = Math.round((aux + Number.EPSILON) * 100) / 100
          self.cupomfbprice = aux
          if (aux != null) {
            retorno = 'R$ ' + aux.toFixed(2).toString().replace('.', ',') + self.planoEscolhido.labelaux
          }
        }
      } else {
        if (self.cupomRetornado.percentageFirstMonth != null) {
          let aux = self.planoEscolhido.pfb - (self.planoEscolhido.pfb * self.cupomRetornado.percentageFirstMonth / 100)
          aux = Math.round((aux + Number.EPSILON) * 100) / 100
          self.cupomfbprice = aux
          if (aux != null) {
            retorno = 'R$ ' + aux.toFixed(2).toString().replace('.', ',') + ' no primeiro mês'
          }
        } else if (self.cupomRetornado.priceFirstMonth != null) {
          self.cupomfbprice = self.cupomRetornado.priceFirstMonth
          retorno = 'R$ ' + self.cupomRetornado.priceFirstMonth.toFixed(2).toString().replace('.', ',') + ' no primeiro mês'
        }
      }
      return retorno
    } else if (self.planoEscolhido && self.promo1) {
      return self.planoEscolhido.price_p1
    } else if (self.planoEscolhido && self.promo2) {
      return self.planoEscolhido.price_p2
    } else if (self.planoEscolhido && self.promo3) {
      return self.planoEscolhido.price_p3
    } else {
      return ''
    }
  }
  isCupomInvalido = false
  async verificaCupom() {
    const self = this
    self.cupomRetornado = null
    self.isCupomInvalido = false
    if (self.cupomDigitado) {
      await self.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      const verificacao = await self.apiCall.get('coupon/verify/' + self.cupomDigitado).pipe(take(1)).toPromise()
      if (verificacao && verificacao.return) {
        self.cupomRetornado = verificacao.return
      } else {
        self.isCupomInvalido = true
        self.hidecupom = false
      }
      await self.spinner.hide()
    }
  }

  formatPreco(plano) {
    return (plano.prices[0].value_cents / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  }


  getCreditCard() {
    if (this.novoCartao.number && Iugu.utils.getBrandByCreditCardNumber(this.novoCartao.number)) {
      return Iugu.utils.getBrandByCreditCardNumber(this.novoCartao.number)
    } else {
      return ""
    }
  }




  consultaCep() {
    const self = this;
    if (self.endereco.zip_code) {
      self.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      cep(self.endereco.zip_code).then(local => {
        self.spinner.hide()
        self.endereco.street = local.street
        self.endereco.district = local.neighborhood
        self.endereco.state = local.state
        self.endereco.city = local.city
      }).catch(erro => {
        self.spinner.hide()
      });
    }

  }

  cartaoCadastrado = null
  cartaoCadastrado2 = null
  async assinar() {
    const self = this
    if (!self.planoEscolhido || !self.planoEscolhido.identifier || !self.formaEscolhida || !self.formaEscolhida.id) {
      return
    }
    console.log('vai acionar spinner')
    await self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    let data = null

    let telefoneAjustado = null

    if (this.usuario.phone) {
      telefoneAjustado = this.usuario.phone
    } else if (this.usuario_phone && this.usuario_phone.e164Number) {
      telefoneAjustado = this.usuario_phone.e164Number
    }

    console.log("usuario", this.usuario)

    console.log("Telefone", telefoneAjustado)

    let captchaToken = null
    let secret = this.generateRandomString()

    captchaToken = await this.generateCaptchaToken(secret)

    secret = btoa(secret)

    data = await self.apiCall.post('customer/me/marketplacesubscription', { 
      product: this.product_id,
      seller: this.seller, 
      name: this.usuario.nome, 
      email: this.usuario.email, 
      phone: telefoneAjustado ? telefoneAjustado : null, 
      plan_identifier: self.planoEscolhido.identifier, 
      tipo_pagamento: self.formaEscolhida.id, 
      endereco: self.endereco, 
      cartao: self.cartaoCadastrado ? self.cartaoCadastrado : null, 
      cartaoMetodo: self.cartaoCadastrado2 ? self.cartaoCadastrado2 : null, 
      cupom: self.cupomRetornado ? self.cupomRetornado : null, 
      utmSourceCheckout: this.utmSource ? this.utmSource : null, 
      utmMediumCheckout: this.utmMedium ? this.utmMedium : null, 
      utmCampaingCheckout: this.utmCampaing ? this.utmCampaing : null, 
      utmContentCheckout: this.utmContent ? this.utmContent : null, 
      sellerId: this.sellerId ? this.sellerId : null, 
      months: this.sellerId && self.planoEscolhido.interval ? self.planoEscolhido.interval : null, 
      plan_type: this.product.plan_type, 
      towAcess: this.product.towAcess, 
      captchaToken: captchaToken, 
      key: secret }).pipe(take(1)).toPromise()

    if (data.success) {

      if (this.sellerId) {

        this.generateEventID()
        fbq('trackSingle', '544083090982331', 'Purchase', { value: this.planPriceFb.toFixed(2), currency: 'BRL' }, { eventID: this.eventID });
        this.sendFbEventMVP('Purchase', this.planPriceFb.toFixed(2));

      }

    }
    setTimeout(async function () {

      if (data.success) {
        console.log("produto", self.product)
        let message = self.product.plan_type === 0 ? 'Compra concluída com sucesso!' : 'Plano assinado com sucesso!'

        await self.app.alert('Tudo certo!', message, 'success')

        let tokenProduct = btoa(data.code).replace("==", "").replace("=", "")
        let tokenInvoice = btoa(data.message).replace("==", "").replace("=", "")

        window.location.href = "https://app.opersonaldigital.com.br/pages/posvenda/" + tokenProduct + "/" + tokenInvoice

      } else {
        console.log('vai esconder spinner')

        await self.spinner.hide()
        await self.app.alert('Ops :(', data.message, 'error')
      }
    }, 3000)
  }
  disableAssinar() {
    let disabled = true
    //logica pra n deixar assinar se for email personal
    if (!this.planoEscolhido || !this.formaEscolhida || (!this.usuario_email || !this.usuario.nome || (this.usuario_phone && this.usuario_phone.e164Number && this.usuario_phone.e164Number == ''))
      || (this.formaEscolhida.id == 'credit_card' && (!this.novoCartao.number || !this.novoCartao.cvv || !this.novoCartao.date || !this.novoCartao.name))
      || (this.formaEscolhida.id == 'bank_slip' && (!this.endereco.number || !this.endereco.zip_code || !this.endereco.state || !this.endereco.street || !this.endereco.district || !this.endereco.city || !this.endereco.cpf_cnpj))) {
      disabled = true
    } else {
      console.log('this.usuario', this.usuario)
      console.log('this.usuario', this.usuario_phone)
      console.log('this.user', this.user)
      console.log("Plano", this.planoEscolhido)
      console.log("Forma", this.formaEscolhida)
      disabled = false
    }
    if (!this.isEmailPersonal) {
      disabled = false
    }
    return disabled
  }

  checkAssinar() {

    console.log(this.acceptTerms)
  }

  async cadastrarCartaoEAssinar() {
    const self = this
    self.erroCartao = ""
    let name = ""
    let cvv = ""
    let date = ""
    let number = ""

    if (self.novoCartao.cvv) {
      cvv = self.novoCartao.cvv.replace(/\s/g, '')
    }
    name = self.novoCartao.name

    if (self.novoCartao.number) {
      number = self.novoCartao.number.replace(/\s/g, '')
    }
    if (self.novoCartao.date) {
      date = self.novoCartao.date.replace(/\s/g, '')
    }

    if (!Iugu.utils.validateCreditCardNumber(number)) {
      self.erroCartao = "Número de cartão inválido"
      return
    }
    if (!Iugu.utils.validateExpirationString(date)) {
      self.erroCartao = "Data de validade inválida"
      return
    }
    if (!Iugu.utils.getBrandByCreditCardNumber(number) || !Iugu.utils.validateCVV(cvv, Iugu.utils.getBrandByCreditCardNumber(number))) {
      self.erroCartao = "Data de validade inválida"
      return
    }

    let cc = Iugu.CreditCard(number,
      date.substring(0, 2), date.substring(3, 7), name.split(' ')[0],
      name.substring(name.indexOf(" ") + 1), cvv);

    let cc2 = Iugu.CreditCard(number,
      date.substring(0, 2), date.substring(3, 7), name.split(' ')[0],
      name.substring(name.indexOf(" ") + 1), cvv);
    await self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    Iugu.createPaymentToken(cc, async function (response) {
      if (response.errors) {
        console.log("Erros", response.errors)
        await self.spinner.hide()
        self.erroCartao = "Erro ao validar seu cartão."
        return
      } else {
        self.cartaoCadastrado = response.id
        await self.spinner.hide()
        await self.assinar()
      }
    });
  }



  async sendFbEventMVP(event, value) {
    const self = this

    this.ipAddress = await this.getIPAddress()

    this.getUserAgent()

    let body = {
      event: event,
      value: value ? value : null,
      fbpCookie: this.fbpCookie ? this.fbpCookie : null,
      ip: this.ipAddress ? this.ipAddress : null,
      userAgent: this.userAgent ? this.userAgent : null,
      eventID: this.eventID
    }

    self.apiCall.post('send/conversionMVP', body).subscribe();

  }

  async generateCaptchaToken(secret) {
    try {
      const token = await this.recaptchaV3Service.execute('purchaseMarketplace' + secret).pipe(take(1)).toPromise();
      return token
    } catch (error) {
      return null
    }
  }

  async getIPAddress() {
    const self = this
    return new Promise<void>((resolve, reject) => {

      self.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
        resolve(res.ip)

      })
    })

  }

  getUserAgent() {
    this.userAgent = window.navigator.userAgent;
  }

  verificaPlano() {
    if (this.planoEscolhido.max_cycles == 1) {
      return " Pagamento Único"

    } else if (this.planoEscolhido.max_cycles > 1) {
      return " por mês durante " + this.planoEscolhido.max_cycles + " meses"

    } else (this.planoEscolhido.max_cycles == 0)
    return " por mês"

  }


}
