import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Api from "app/helpers/api";
import {
  MarketplaceProductsRequestType,
  MarketplaceProductsReturnType,
  ProductType,
} from "app/personal/pages/finance-store/services-and-products/services-and-products.types";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { asyncScheduler, scheduled } from "rxjs";
import { map } from "rxjs/operators";

const GET_PRODUCTS_LIMIT = 100;

@Component({
  selector: "app-products-addition-to-store-modal",
  templateUrl: "./products-addition-to-store-modal.component.html",
  styleUrls: ["./products-addition-to-store-modal.component.scss"],
})
export class ProductsAdditionToStoreModalComponent implements OnInit {
  apiCall = this.api.new().silent();

  products: ProductType[] = [];
  productsRaw: ProductType[] = [];

  isLoading = false;
  selectOpen = false;

  @Input("callback") callback: ((status: "success" | "error") => void) | null =
    null;

  constructor(
    private cdr: ChangeDetectorRef,
    private activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private api: Api
  ) {}

  ngOnInit(): void {
    this.getProducts({
      limit: GET_PRODUCTS_LIMIT,
      offset: 0,
      onStore: false,
      status: true,
    }).subscribe(() => this.cdr.detectChanges());
  }

  getProducts(query: MarketplaceProductsRequestType) {
    this.isLoading = true;

    return this.apiCall
      .get<MarketplaceProductsReturnType | null>(
        `marketplace/products?${new URLSearchParams(
          Object.entries(query) as unknown as string[][]
        ).toString()}`
      )
      .pipe(
        map((response) => {
          this.isLoading = false;

          if (response.success && response.return) {
            this.productsRaw = response.return.rows;
          } else {
            this.toastr.error(response.message, "Ops :(");
          }

          return scheduled([response], asyncScheduler);
        })
      );
  }

  deleteProduct(productId: number) {
    this.products = this.products.filter((item) => item.id !== productId);
  }

  clearProducts() {
    this.products = [];
  }

  submit() {
    if (this.products.length === 0) {
      this.toastr.error("Você deve selecionar ao menos 1 produto!");

      return;
    }

    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    const body: { products: { id: number }[] } = { products: [] };

    this.products.forEach((item) => body.products.push({ id: item.id }));

    this.apiCall
      .put("marketplace/products/reorder", body)
      .subscribe((response) => {
        this.spinner.hide();

        if (response.success) {
          this.toastr.success(
            "Produto adicionado a sua loja com Sucesso!",
            "Tudo certo!"
          );

          this.callback?.("success");
        } else {
          this.callback?.("error");
          this.toastr.error(response.message, "Ops :(");
        }
      });
  }

  toggleSelect() {
    this.selectOpen = !this.selectOpen;
  }

  closeModal() {
    this.activeModal.dismiss("closed-by-user");
  }
}
