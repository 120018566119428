import Swal from "sweetalert2";
import Api from "app/helpers/api";
import App from "app/helpers/app";
import * as CryptoJS from "crypto-js";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { sellerStore, SellerStoreContact } from "./stories.types";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { CloudStorageService } from "app/shared/services/cloud-storage/cloud-storage.service";
import { MidiaStoreComponent } from "./midia-store/midia-store.component";
import { take } from "rxjs/operators";
import { ShareSiteComponent } from "app/modules/share-site/share-site.component";
import { ModalCropComponent } from "app/modules/modal-crop/modal-crop.component";
import { Router } from "@angular/router";
@Component({
  selector: "app-store",
  templateUrl: "./store.component.html",
  styleUrls: ["./store.component.scss"],
  providers: [CloudStorageService],
})
export class StoreComponent implements OnInit {
  user = null;
  idUser: string;
  thumbnail_url: any;
  seller: sellerStore;
  storeForm: FormGroup;
  thumbnail_image: File;
  thumbnail_image_sha1: any;
  isMediaStoreModalOpen = false;
  apiCall = this.api.new().silent();
  sellerContact: SellerStoreContact[] = [];
  mediaInputs: { type: string; link: string }[] = [];
  contactTypes: { id: number; name: string; icon: string }[] = [];

  constructor(
    private api: Api,
    private app: App,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private cloudStorage: CloudStorageService
  ) {}

  async ngOnInit() {
    let localAccount = null;
    this.initializeForm();
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const subaccount = await this.apiCall
      .get("seller/me/marketplaceaccountlocal")
      .pipe(take(1))
      .toPromise();

    if (!subaccount.return) {
      this.router.navigate(["/page/personal/finance/account"]);
    } else {
      localAccount = subaccount.return;
    }
    this.userInfo();
    this.loadContactTypes();
  }

  userInfo(): void {
    this.apiCall.get("user/seller-store").subscribe(
      (data) => {
        this.seller = data.return;
        this.updateContacts(this.seller.SellerStoreContact);
        this.storeForm.patchValue({
          slug: this.seller.slug,
          name: this.seller.name,
          description: this.seller.description,
        });
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        console.log("Erro ao obter informações da loja:", error);
      }
    );
  }

  initializeForm() {
    this.storeForm = this.fb.group({
      slug: [
        null,
        [
          Validators.maxLength(25),
          Validators.pattern("^[a-zA-Z0-9]+$"),
          Validators.required,
        ],
      ],
      name: ["", [Validators.required, Validators.maxLength(100)]],
      description: ["", [Validators.required, Validators.maxLength(255)]],
      banner: ["", [Validators.required]],
      SellerStoreContact: this.fb.array([]),
    });
  }

  convertToLowerCase() {
    const slugControl = this.storeForm.get("slug");
    if (slugControl) {
      slugControl.setValue(slugControl.value.toLowerCase(), {
        emitEvent: false,
      });
    }
  }

  get contacts(): FormArray {
    return this.storeForm.get("SellerStoreContact") as FormArray;
  }

  private updateContacts(contacts: any[]): void {
    if (!contacts || contacts.length === 0) {
      this.storeForm.setControl("SellerStoreContact", this.fb.array([]));
      return;
    }

    const contactFGs = contacts.map((contact) => this.fb.group(contact));
    const contactFormArray = this.fb.array(contactFGs);
    this.storeForm.setControl("SellerStoreContact", contactFormArray);
  }

  async onFileChange(event: Event, type: string) {
    const self = this;
    const input = event.target as HTMLInputElement;

    if (!input.files || input.files.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Você precisa escolher uma imagem!",
      });
      return;
    }

    const file = input.files[0];

    if (
      file.type !== "image/png" &&
      file.type !== "image/jpg" &&
      file.type !== "image/jpeg"
    ) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "São permitidos apenas imagens!",
      });
      return;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      this.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });

      this.openCropModal(file);
      this.spinner.hide();

      img.onerror = () => {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Erro ao carregar a imagem. Tente novamente.",
        });
      };
    };
    input.value = "";
  }

  base64ToBlob(base64: string, contentType = "", sliceSize = 512): Blob {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  blobToFile(blob: Blob, fileName: string): File {
    return new File([blob], fileName, { type: blob.type });
  }

  openCropModal(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const modalRef = this.modalService.open(ModalCropComponent, {
        centered: true,
        size: "lg",
      });
      modalRef.componentInstance.source = reader.result as string;
      modalRef.componentInstance.aspectRatio = 16 / 9;
      modalRef.componentInstance.croppedImageEvent.subscribe(
        (croppedImage: string) => {
          this.thumbnail_url = croppedImage;
          const contentType = "image/jpeg";
          const blob = this.base64ToBlob(croppedImage, contentType);
          this.thumbnail_image = this.blobToFile(blob, "cropped_image.jpeg");
        }
      );
      modalRef.result
        .then((croppedImage: string) => {
          if (croppedImage) {
            this.thumbnail_url = croppedImage;
          }
        })
        .catch((error: any) => {
          console.log("Modal dismissed with error:");
        });
    };
    reader.onerror = (error) => {
      console.error("Error reading file:", error);
    };
  }

  async onSubmit() {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    const formValues = this.storeForm.value;
    this.seller.name = formValues.name;
    this.seller.description = formValues.description;

    const uploadImage = async (file: File): Promise<string | null> => {
      if (!file) return null;
      try {
        return new Promise<string | null>((resolve, reject) => {
          this.cloudStorage
            .upload(file, { fileType: file.type, fileExt: "jpeg" })
            .subscribe(
              (url) => resolve(url),
              (error) => reject(null)
            );
        });
      } catch (error) {
        console.error("Error in uploadImage:", error);
        return null;
      }
    };

    try {
      if (this.thumbnail_image) {
        this.seller.banner = await uploadImage(this.thumbnail_image);
      }

      delete this.seller.deletedAt;
      delete this.seller.createdAt;
      delete this.seller.updatedAt;
      delete this.seller.active;
      delete this.seller.lastSlugUpdate;
      delete this.seller.feedback;
      delete this.seller.sellerId;
      const sellerStoreContact =
        (this.storeForm.get("SellerStoreContact") as FormArray).value || [];
      delete this.seller.SellerStoreContact;
      sellerStoreContact.forEach((contact) => {
        delete contact.createdAt;
        delete contact.updatedAt;
        delete contact.deletedAt;
      });
      const slug = formValues.slug;
      delete this.seller.slug;
      const id = this.seller.id;
      delete this.seller.id;

      let body;
      if (id) {
        body = {
          sellerStore: this.seller,
          SellerStoreContact: sellerStoreContact,
        };
      } else {
        if (!slug || !this.storeForm.get("slug").valid) {
          this.spinner.hide();
          await this.app.alert(
            "Atenção",
            "O campo dominio é obrigatório e deve conter letras de A-Z e números de 0-9.",
            "warning"
          );
          return;
        }

        body = {
          sellerStore: { ...this.seller, slug: slug },
          SellerStoreContact: sellerStoreContact,
        };
      }
      console.log("body", body);
      const chamada = id
        ? this.apiCall.put(`seller-store/${id}`, body)
        : this.apiCall.post("seller-store", body);

      chamada.subscribe(async (data) => {
        console.log("post/put", data);
        this.spinner.hide();
        if (data.success) {
          await this.app.alert(
            "Tudo certo!",
            id ? "Loja atualizada com sucesso" : "Loja cadastrada com sucesso",
            "success"
          );
          this.userInfo();
        } else {
          await this.app.alert("Ops :(", data.message, "error");
        }
      });
    } catch (error) {
      this.spinner.hide();
      console.error("Error on submitting banner:", error);
      await this.app.alert(
        "Ops :(",
        "Ocorreu um erro ao enviar o banner",
        "error"
      );
    }
  }

  computeFileSha1(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const file_result = reader.result as ArrayBuffer;
        const file_wordArr = CryptoJS.lib.WordArray.create(file_result);
        const sha1_hash = CryptoJS.SHA1(file_wordArr);
        resolve(sha1_hash.toString());
      };
      reader.onerror = (error) => reject(error);
    });
  }

  updateSlug(idSeller: string) {
    const body = { slug: this.storeForm.get("slug")?.value };
    try {
      this.apiCall
        .put(`seller-store/slug/${idSeller}`, body)
        .subscribe((response: any) => {
          console.log("slug", response);
          if (response.success) {
            this.toastr.success("Domínio atualizado com sucesso");
          } else {
            this.toastr.error(response.error);
          }
        });
    } catch (error) {
      console.log("Erro ao tentar atualizar domínio", error);
      this.toastr.error("Erro ao tentar atualizar domínio");
    }
  }

  activeSite(idSeller: string) {
    try {
      this.apiCall
        .put(`seller-store/active/${idSeller}`)
        .subscribe((response: any) => {
          console.log(response);
          if (response.success) {
            this.toastr.success("Site ativado com sucesso");
            this.seller.active = true;
          } else {
            this.toastr.error("Erro ao tentar ativar site", response.error);
          }
        });
    } catch (error) {
      console.log("Erro ao tentar ativar site", error);
      this.toastr.error("Erro ao tentar ativar site");
    }
  }

  inactiveSite(idSeller: string) {
    try {
      this.apiCall
        .put(`seller-store/inactive/${idSeller}`)
        .subscribe((response: any) => {
          if (response.success) {
            this.toastr.success("Site desativado com sucesso");
            this.seller.active = false;
          } else {
            this.toastr.error("Erro ao tentar desativar site", response.error);
          }
        });
    } catch (error) {
      console.log("Erro ao tentar desativar site", error);
      this.toastr.error("Erro ao tentar desativar site");
    }
  }

  cancelar() {
    this.storeForm.reset({
      slug: this.seller?.slug || "",
      name: this.seller?.name || "",
      description: this.seller?.description || "",
      SellerStoreContact: this.seller?.SellerStoreContact || "",
      banner: this.seller?.banner || null,
    });

    this.thumbnail_url = null;
  }

  loadContactTypes() {
    this.apiCall
      .get("seller-store-contact-types")
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.contactTypes = data.return;
        },
        (error) => {
          console.error("Error loading contact types:", error);
        }
      );
  }

  openContactModal() {
    if (this.contactTypes.length === 0) {
      this.loadContactTypes();
    }

    const modalRef = this.modalService.open(MidiaStoreComponent);
    modalRef.componentInstance.contactTypes = this.contactTypes;

    modalRef.result.then(
      (result) => {
        if (result) {
          const contactData = {
            contact: result.contact,
            contactTypeId: result.contactTypeId,
          };

          this.addContact(contactData);
        }
      },
      (reason) => {
        console.log("Modal dismissed:", reason);
      }
    );
  }

  getContactTypeName(id: number): string {
    if (!this.contactTypes.length) return "";
    const type = this.contactTypes.find((t) => {
      return Number(t.id) === Number(id);
    });
    return type ? type.name : "";
  }

  getContactTypeIcon(id: number): string {
    if (!this.contactTypes.length) return "";
    const type = this.contactTypes.find((t) => {
      return Number(t.id) === Number(id);
    });
    return type ? type.icon : "";
  }

  addContactToServer(contactData: any) {
    const body = {
      sellerStoreId: this.seller.id,
      contactTypeId: contactData.contactTypeId,
      contact: contactData.contact,
    };

    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.apiCall.post("seller-store-contact", body).subscribe(
      (response) => {
        console.log(response);
        if (response.success) {
          this.toastr.success("Contato adicionado com sucesso!");

          const contactFormGroup = this.fb.group({
            id: [response.return.id],
            contact: [contactData.contact],
            contactTypeId: [contactData.contactTypeId],
          });

          (this.storeForm.get("SellerStoreContact") as FormArray).push(
            contactFormGroup
          );
        } else {
          this.toastr.error("Falha ao adicionar contato.");
        }
        this.spinner.hide();
      },
      (error) => {
        console.error("Erro ao adicionar contato:", error);
        this.toastr.error("Erro ao adicionar contato.");
        this.spinner.hide();
      }
    );
  }

  addContact(contactData: any) {
    if (!this.seller.id) {
      this.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      (this.storeForm.get("SellerStoreContact") as FormArray).push(
        this.fb.group(contactData)
      );
      this.spinner.hide();
      this.toastr.success("Contato adicionado localmente!");
    } else {
      this.addContactToServer(contactData);
    }
  }

  removeContact(index: number) {
    if (this.seller.id) {
      const contactId = (this.storeForm.get("SellerStoreContact") as FormArray)
        .at(index)
        .get("id")?.value;

      if (!contactId) {
        this.toastr.error("ID do contato não encontrado.");
        return;
      }

      this.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });

      this.apiCall.delete(`seller-store-contact/${contactId}`).subscribe(
        (response) => {
          console.log(response);
          if (response.success) {
            (this.storeForm.get("SellerStoreContact") as FormArray).removeAt(
              index
            );
            this.toastr.success("Contato removido com sucesso!");
          } else {
            this.toastr.error("Falha ao remover contato.");
          }
          this.spinner.hide();
        },
        (error) => {
          console.error("Erro ao remover contato:", error);
          this.toastr.error("Erro ao remover contato.");
          this.spinner.hide();
        }
      );
    } else {
      (this.storeForm.get("SellerStoreContact") as FormArray).removeAt(index);
      this.toastr.success("Contato removido localmente!");
    }
  }

  openShareModal() {
    const modalRef = this.modalService.open(ShareSiteComponent);
    modalRef.componentInstance.siteUrl = `https://app.opersonaldigital.com.br/pages/loja/${this.seller.slug}`;
    modalRef.componentInstance.whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
      `https://app.opersonaldigital.com.br/pages/loja/${this.seller.slug}`
    )}`;
  }

  openModal(content: any, size?: string) {
    return this.modalService.open(content, {
      centered: true,
      size: size || "xl",
    });
  }
}
